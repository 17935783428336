<template>
  <div style="background-image: url(//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/object/xnsy/xnsy.png);
    background-repeat: no-repeat;
    background-size: 100% 100%; height:100vh;" id="xnsy">
    <div class="top-title">VR虚拟实验解决方案</div>
    <div class="title">　　利用虚拟现实技术，打破时间、场景地限制，随时随地开展VR小学科学、初高中物理、化学、生物等实验教学，无需考虑耗材、安全、污染性等问题，自由开展试错性学习。</div>
  </div>
</template>

<script>

export default {
  name: 'SliderBox',
  components: {

  },
  data () {
      return {
       
      }
  },
  created () {

  }
}
</script>
<style scoped>
.top-title{
    position: relative;
    top: 30%;
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-style: normal;
    font-size: 46px;
    letter-spacing: 0.7px;
    text-align: center;
    text-shadow: 2px 2px 2px #686767;
}
.title{
    position: relative;
    top: 50%;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    font-size: 20px;
    line-height: 39px;
    color: #fff;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    text-shadow: 2px 2px 2px #686767;
}
</style>
