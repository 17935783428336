<template>
    <slider/>
    <!-- 集中培训学习方案 -->
    <section class="features-style-one  bg-style-one padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>集中培训学习方案</h3>
                <br>
            </div>
            <el-row :gutter="20">
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
            </el-row>
        </div>
    </section>
    <!-- 自主学习体验方案 -->
    <section class="features-style-one bg-style-two padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>自主学习体验方案</h3>
                <br>
            </div>
            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
    </section>
    <!-- 集中培训学习方案 -->
    <section class="features-style-one  bg-style-one padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>配套资源</h3>
                <br>
                <span>涵盖浩瀚宇宙、环球旅行、科学探索、人文艺术、极限挑战等主题；</span>
            </div>
            <el-row :gutter="20">
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
            </el-row>
        </div>
    </section>
    <!-- 落地形式 -->
    <section class="features-style-one bg-style-two padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>落地形式</h3>
                <br>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="12">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
import slider from './slider.vue'
export default {
    components: {
        slider
    },
    data () {
        return {
            
        }
    },
    created () {

    },
    mounted () {

    },
    methods: {

    }
}
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  margin-bottom:20px;
  background-color:rgba(248, 248, 248, 1);
  border-radius: 4px;
  min-height: 36px;
}
</style>
