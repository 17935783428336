<template>
  <div style="background-image: url(//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;">
    <!--Main Slider-->
    <section class="main-slider" style=" background: hsla(0,0%,100%,.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(162%) blur(20px); height:100vh;">
        <div class="rev_slider_wrapper fullwidthbanner-container"  id="rev_slider_one_wrapper" data-source="gallery">
            <div class="rev_slider fullwidthabanner" id="rev_slider_one" data-version="5.4.1">
                <ul>
                    <!-- thrid slider                 -->
                    <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1685" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-transition="fade">
                        
                        <!-- <img alt="" class="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="bottom center" data-bgrepeat="no-repeat" data-no-retina="" src="img/slider/slider-three/slider-3-bg.jpg">  -->


                        <div class="tp-caption  hidden-xs" 
                        id="slide-46-layer-1" 
                        data-x="['right','right','right','right']" data-hoffset="['375','375','150','375']" 
                        data-y="['top','top','top','top']" data-voffset="['84','84','115','84']" 
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 33;"><img src="img/slider/slider-three/top-man-face.png"  data-ww="['139px','139px','90px','139px']" data-hh="['162px','160px','100px','160px']" width="1180" height="727" alt="awesome image"> </div>

                        <div class="tp-caption  hidden-xs" 
                        id="slide-46-layer-2" 
                        data-x="['right','right','right','right']" data-hoffset="['365','365','141','365']" 
                        data-y="['top','top','top','top']" data-voffset="['179','179','174','179']" 
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 34;"><img src="img/slider/slider-three/top-man-hand.png" alt="a"  data-ww="['140px','140px','90px','140px']" data-hh="['171px','171px','100px','171px']" width="1180" height="727" data-no-retina> </div>

                        <div class="tp-caption  " 
                        id="slide-46-layer-3" 
                        data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['90','60','60','60']" 
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":900,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 33;"><img src="https://edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/37661662379103_.pic.jpg" alt="a"  data-ww="['842px','750px','500px','300px']" data-hh="['600px','500px','350px','250px']" width="1180" height="727"> </div>

                        <div class="tp-caption  " 
                        id="slide-46-layer-4" 
                        data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" 
                                    data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":1100,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 33;"><img src="img/slider/slider-three/man-1.png" alt="a" data-ww="['344px','320px','220px','190px']" data-hh="['504px','480px','300px','280px']" width="151" height="266" data-no-retina> </div>

                        <div class="tp-caption  " 
                        id="slide-46-layer-5" 
                        data-x="['center','center','center','center']" data-hoffset="['130','30','30','30']" 
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" 
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 33;"><img src="img/slider/slider-three/woman.png" alt="a" data-ww="['247px','230px','190px','150px']" data-hh="['477px','430px','380px','250px']" width="151" height="266" data-no-retina> </div>

                        <div class="tp-caption  " 
                        id="slide-46-layer-6" 
                        data-x="['center','center','center','center']" data-hoffset="['-50','-100','-100','-100']" 
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['90','65','50','50']" 
                                    data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
            
                        data-type="image" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"

                        style="z-index: 30;"><img src="img/slider/slider-three/tree.png" alt="a" data-ww="['670px','600px','500px','180px']" data-hh="['285px','240px','220px','100px']" width="151" height="266" data-no-retina> </div>


                        <div class="tp-caption"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-width="['650','750','700','420']"
                        data-whitespace="normal"
                        data-hoffset="['0','0','0','0']"
                        data-voffset="['-120','-100','-115','-115']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        style="z-index: 35; white-space: nowrap;">
                            <h2>我们致力于</h2>
                        </div>
                        
                        <div class="tp-caption" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-width="['550','500','450','420']"
                        data-whitespace="normal"
                        data-hoffset="['0','0','0','0']"
                        data-voffset="['40','50','25','15']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        style="z-index: 35; white-space: nowrap;">
                            <div class="text"> 把基于AI+XR技术的学习方式带给每所学校，每个教师，每个学生<br>
                                               让教育形式更多样，资源更丰富，学习更有趣<br>
                                               为教育带来无限可能.......<br>
                            </div>
                        </div>
                        
                        <div class="tp-caption tp-resizeme" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-width="['550','600','650','420']"
                        data-whitespace="normal"
                        data-hoffset="['0','0','0','0']"
                        data-voffset="['155','160','120','120']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                        style="z-index: 35; white-space: nowrap;">
                            <!-- <a href="#" class="banner-btn">Learn More</a> -->
                        </div>
                    
                    </li>
                </ul>
                <div class="container">
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'SliderBox',
  components: {

  },
  data () {
      return {
       
      }
  },
  created () {

  }
}
</script>
<style scoped>
.container {
    position: relative;
    top: 90vh;
    width: 24px;
    height: 24px;
}
.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
}
.chevron:first-child {
    animation: move 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}
.chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}
.chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}
@keyframes move {
    25% {
        opacity: 1;
    }
    33% {
        opacity: 1;
        transform: translateY(30px);
    }
    67% {
        opacity: 1;
        transform: translateY(40px);
    }
    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}
@keyframes pulse {
    to {
        opacity: 1;
    }
}
</style>
