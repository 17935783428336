  <template>
    <div class="home">
      <Slideshow/>
    </div>
    <el-affix :offset="60" v-show="isMob == false">
      <el-anchor :offset="100" id="anchor-left">
        <el-anchor-link href="/">
          <h4>北京幻育</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#zhengce`">
          <h4>政策背景</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#yingyong`">
          <h4>八大应用方向</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#selecthuanyu`">
          <h4>为什么选择幻育</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#zizhi`">
          <h4>企业资质</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#anli`">
          <h4>部分案例</h4>
        </el-anchor-link>
      </el-anchor>
    </el-affix>
  </template>
  
  <script>
  import Slideshow from '@/components/case-share/slideshow.vue'
  import { isMobile } from '@/utils/tools'
  export default {
    headers: {
      'Content-Type': 'application/json'
    },
    isMob: false,
    name: 'HomeView',
    components: {
      Slideshow
    },
    created () {
      this.isMob = isMobile()
    },
    mounted () {

    },
    methods: {

    }
  }
  </script>
  <style scoped>
    #anchor-left{
      z-index: 1000;
      position:fixed;
      left: 15px;
      width: 180px;
      bottom: 35%;
      color: #f5f5f5;
      background-color: rgba(0, 0, 0, 0.21);
      padding: 12px;
      border-radius: 5px
    }
    /deep/.el-anchor__link{
      color: #f5f5f5;
    }
    /deep/.el-anchor__link:hover{
      color: #409eff;
    }
  </style>
  