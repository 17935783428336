<template>
  <div style="background-repeat: no-repeat;background-size: 100% 100%; height:100vh;">
    <div class="top-title">VR校园解决方案</div>
    <div class="title">　　VR校园不仅可全景记录并展示学校的环境建设、文化建设、校史、教学教研成果等，还可以用来接待参观、记录校园大事记、保存历史影像、展示学校文化、学校官网、双师直播课堂、课程资源、校园安防等数字化资源。。</div>
    <iframe src="https://api.cloud.edu-xr.com/pano-manage/preview?view_uuid=6caafa4435899612" frameborder="0" style="height:80vh; width: 100vw;"  id="vrschool"></iframe>
  </div>
</template>

<script>

export default {
  name: 'SliderBox',
  components: {

  },
  data () {
      return {
       
      }
  },
  created () {

  }
}
</script>
<style scoped>
.top-title{
    margin-top: -55px;
    position: relative;
    top: 25%;
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-style: normal;
    font-size: 46px;
    letter-spacing: 0.7px;
    text-align: center;
    text-shadow: 2px 2px 2px #686767;
}
.title{
    margin-top: -55px;
    position: relative;
    top: 50%;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    font-size: 20px;
    line-height: 39px;
    color: #fff;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    text-shadow: 2px 2px 2px #686767;
}
</style>
