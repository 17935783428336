import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CaseShareView from '../views/CaseShareView.vue'
import ProjectView from '../views/ProjectView.vue'
import Download from '../views/Download.vue'

const routes = [
  { // 首页
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
			title: '5G+教育-北京幻育科技有限公司',
			content: {
				keywords: '5GVR+教育-北京幻育',
				description: '我们致力于把基于AI+XR技术的学习方式带给每所学校，每个教师，每个学生让教育形式更多样，资源更丰富，学习更有趣为教育带来无限可能。'
			}
		}
  },
  { // 案例分享
    path: '/caseShare',
    name: 'CaseShare',
    component: CaseShareView,
    meta: {
			title: '5G+教育-北京幻育科技有限公司',
			content: {
				keywords: '5GVR+教育-北京幻育',
				description: '我们致力于把基于AI+XR技术的学习方式带给每所学校，每个教师，每个学生让教育形式更多样，资源更丰富，学习更有趣为教育带来无限可能。'
			}
		}
  },
  { // 建设方案
    path: '/project',
    name: 'Project',
    component: ProjectView,
    meta: {
			title: '5G+教育-北京幻育科技有限公司',
			content: {
				keywords: '5GVR+教育-北京幻育',
				description: '我们致力于把基于AI+XR技术的学习方式带给每所学校，每个教师，每个学生让教育形式更多样，资源更丰富，学习更有趣为教育带来无限可能。'
			}
		}
  },
  { // 公司常用软件下载页面
    path: '/d',
    name: 'Download',
    component: Download,
    meta: {
			title: '软件下载'
		}
  },
  { path: '/:pathMatch(.*)', component: () => import('@/views/404') }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
