<template>
<div id="box">
    <el-carousel height="auto" autoplay :interval="22000" :motion-blur="true" indicator-position="outside">
      <el-carousel-item style="height:100vh;">
        <iframe src="https://api.cloud.edu-xr.com/pano-manage/preview?view_uuid=6833d994e5d6547a" frameborder="0" style="height:100vh; width: 100vw;"></iframe>
      </el-carousel-item>
      <el-carousel-item style="height:100vh;">
        <iframe src="https://api.cloud.edu-xr.com/pano-manage/preview?view_uuid=d4f5d1e09141d7e6" frameborder="0" style="height:100vh; width: 100vw;"></iframe>
      </el-carousel-item>
      <el-carousel-item style="height:100vh;">
        <iframe src="https://api.cloud.edu-xr.com/pano-manage/preview?view_uuid=47afa1e879a4f23e" frameborder="0" style="height:100vh; width: 100vw;"></iframe>
      </el-carousel-item>
    </el-carousel>
    <!-- <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
    </div> -->
</div>
<div id="image-text">
    <el-carousel height="auto" autoplay :interval="12000" :motion-blur="true" indicator-position="outside" @change="changeCarousel" ref="carousel" @setActiveItem="setActiveItem">
      <el-carousel-item style="height:50vh; border-radius: 9px;" v-for="( val, i ) in imageText" :key="i">
        <el-image :src="val.url" fit="scale-down" style="height:100%; width: 100%; background-color: black;"/>
        <div class="txt">{{val.name}}</div>
        <div class="txtBottom">{{val.label}}</div>
      </el-carousel-item>
    </el-carousel>
    <el-space wrap style="margin: 10px;border-radius: 9px;">
        <div v-for="( val, i ) in imageText" :key="i" @click="setActiveItem(i)" style="border-radius: 9px;">
            <el-image class="imgActive" :src="val.url" fit="cover" style="height:100px; width: 100px; border-radius: 9px;" v-if="i == index"/>
            <el-image class="imgNotActive"  :src="val.url" fit="cover" style="height:100px; width: 100px; border-radius: 9px;" v-else/>
            <div class="imgBottom">{{val.label}}</div>
        </div>
    </el-space>
</div>
</template>

<script>

export default {
  components: {

  },
  data () {
      return {
        index: 0,
        imageText:[
            {
                name: '北京教科院',
                label: '北京科学研究院职工合照',
                url: 'https://www.bjesr.cn/d/file/tpxw1/2023-09-11/c819085baec796f08246e92fbe2a053d.jpg'
            },
            {
                name: '北京教科院',
                label: '中国共产党北京教育科学研究院第二次党员代表大会',
                url: 'https://www.bjesr.cn/d/file/tpxw1/2023-07-27/a832d58a4feae6748f1764ed5dc796b4.jpg'
            },
            {
                name: '北京教科院',
                label: '学习贯彻习近平新时代中国特色社会主义思想主题教育',
                url: 'https://www.bjesr.cn/d/file/tpxw1/2023-07-03/fb89863aed2a696b31abe0119975432c.png'
            },
        ]
      }
  },

  created () {

  },

  methods: {
    setActiveItem(index) {
      if (this.index == index) {
        return;
      }
      this.index = index
      this.$refs.carousel.setActiveItem(index);
    },
    changeCarousel(index){
        this.index = index
    }
  }
}
</script>
<style scoped>
/deep/.el-carousel__indicators--outside {
    display: none;
}
#box{
    height: 100vh;
}
#image-text {
    border-radius: 9px;
    width: 50%;
    z-index: 100;
    position: absolute;
    /* -webkit-backdrop-filter: saturate(180%) blur(20px); */
    /* backdrop-filter: saturate(162%) blur(20px); */
    background-color: rgba(0, 0, 0, 0.21);
    height: auto;
    top: 50%; /* 将元素从顶部边界向下移动到页面高度的50% */
    left: 50%; /* 将元素从左侧边界向右移动到页面宽度的50% */
    transform: translate(-50%, -50%); 
}
.txt{
    position: absolute;
    color: #f5f5f5;
    background-color: rgba(0, 0, 0, 0.21);
    width: 100%;
    top: 0px;
    padding: 15px;
}
.txtBottom{
    border-radius: 9px;
    position: absolute;
    color: #f5f5f5;
    background-color: rgba(0, 0, 0, 0.21);
    width: 100%;
    bottom: 5px;
    padding: 15px;
}
.imgBottom{
    border-radius: 0px 0px 9px 9px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    color: #f5f5f5;
    background-color: rgba(0, 0, 0, 0.21);
    width:100px;
    height: 31px;
    bottom: 40px;
    padding-left:1px;
    margin-bottom: -31px;
}

.imgActive::before{
    cursor: pointer;
    border-radius: 9px;
    content: '';
    display: block;
    position: absolute; /* 设置绝对定位 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 设置遮罩层颜色和透明度 */
    opacity: 1; /* 初始透明度为0（即不可见） */
    transition: opacity 0.3s ease-out; /* 设置过渡效果 */
    z-index: 1; /* 设置遮罩层比图片层级高 */
}

.imgNotActive::before {
  cursor: pointer;
  border-radius: 9px;
  content: '';
  display: block;
  position: absolute; /* 设置绝对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 设置遮罩层颜色和透明度 */
  opacity: 0; /* 初始透明度为0（即不可见） */
  transition: opacity 0.3s ease-out; /* 设置过渡效果 */
  z-index: 1; /* 设置遮罩层比图片层级高 */
}
.imgNotActive:hover::before {
  cursor: pointer; /* 设置鼠标为可拖动 */
  opacity: 1; /* 鼠标移入时设置遮罩层透明度为1（即可见） */
}
.contentBox{
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container {
    position: relative;
    bottom: 8vh;
    width: 24px;
    height: 24px;
}
.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
}
.chevron:first-child {
    animation: move 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}
.chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}
.chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}
@keyframes move {
    25% {
        opacity: 1;
    }
    33% {
        opacity: 1;
        transform: translateY(30px);
    }
    67% {
        opacity: 1;
        transform: translateY(40px);
    }
    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}
@keyframes pulse {
    to {
        opacity: 1;
    }
}
img{
    cursor: pointer;
    border-radius: 9px;
}
</style>
