<template>
  <div style="background-image: url(//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/object/kpjy/kpjy.png);
    background-repeat: no-repeat;
    background-size: 100% 100%; height:100vh;" id="kpjy">
    <div class="top-title">VR科普教育解决方案</div>
    <div class="title">　　科普教育方案将VR技术与科普教育深度结合，丰富了传统科普教育课的形式，突破时间、空间上的限制，利用虚拟现实技术，大到宇宙天体、小至原子粒子，学生都可以进入这些物体的内部进行观察。例如学生可以乘坐飞船进入虚拟太空，遨游太阳系，以飞船驾驶舱视角近距离观察各个行星，理解真实的天体运行规律，这是传统教育媒体资源所无法比拟的。</div>
  </div>
</template>

<script>

export default {
  name: 'SliderBox',
  components: {

  },
  data () {
      return {
       
      }
  },
  created () {

  }
}
</script>
<style scoped>
.top-title{
    position: relative;
    top: 30%;
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-style: normal;
    font-size: 46px;
    letter-spacing: 0.7px;
    text-align: center;
    text-shadow: 2px 2px 2px #686767;
}
.title{
    position: relative;
    top: 50%;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    font-size: 20px;
    line-height: 39px;
    color: #fff;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    text-shadow: 2px 2px 2px #686767;
}
</style>
