<template>
    <div class="home">
      <Hsdj/>
      <VrSchool/>
      <kpjy/>
      <xnsy/>
    </div>
    
    <el-affix :offset="60" v-show="isMob == false">
      <el-anchor :offset="100" id="anchor-left">
        <el-anchor-link :href="`#hsdj`">
          <h4>红色思政</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#vrschool`">
          <h4>VR校园</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#kpjy`">
          <h4>科普教育</h4>
        </el-anchor-link>
        <el-anchor-link :href="`#xnsy`">
          <h4>虚拟实验</h4>
        </el-anchor-link>
        
      </el-anchor>
    </el-affix>
  </template>
  
  <script>
  import Hsdj from '@/components/project/hsdj/index.vue'
  import VrSchool from '@/components/project/vrschool/index.vue'
  import kpjy from '@/components/project/kpjy/index.vue'
  import xnsy from '@/components/project/xnsy/index.vue'
  import { isMobile } from '@/utils/tools'
  export default {
    headers: {
      'Content-Type': 'application/json'
    },
    isMob: false,
    name: 'ProjectView',
    components: {
      Hsdj,
      VrSchool,
      kpjy,
      xnsy
    },
    created () {
      this.isMob = isMobile()
    },
    mounted () {

    },
    methods: {

    }
  }
  </script>
  <style scoped>
    #anchor-left{
      z-index: 1000;
      position:fixed;
      left: 15px;
      width: 180px;
      bottom: 35%;
      color: #f5f5f5;
      background-color: rgba(0, 0, 0, 0.21);
      padding: 12px;
      border-radius: 5px
    }
    /deep/.el-anchor__link{
      color: #f5f5f5;
    }
    /deep/.el-anchor__link:hover{
      color: #409eff;
    }
  </style>
  