<template>
    <slider/>
    <!-- 集中培训学习方案 -->
    <section class="features-style-one  bg-style-one padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>集中培训学习方案</h3>
                <br>
            </div>
            <el-row :gutter="20">
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
            </el-row>
        </div>
    </section>
    <!-- 自主学习体验方案 -->
    <section class="features-style-one bg-style-two padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>自主学习体验方案</h3>
                <br>
            </div>
            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
    </section>
    <!-- 集中培训学习方案 -->
    <section class="features-style-one  bg-style-one padding-two">
        <div class="thm-container">
            <div class="title">
                <h3>配套资源</h3>
                <br>
                <span>涵盖新中国成立70周年、重走红军路、革命战争、建党100周年4个主题的重大历史事件还原、英雄楷模人物学习、当代中国举世瞩目的成就回顾等内容；</span>
            </div>
            <el-row :gutter="20">
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
                <el-col :span="8"><div class="grid-content ep-bg-purple" /></el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
import slider from './slider.vue'
export default {
    components: {
        slider
    },
    data () {
        return {
            
        }
    },
    created () {

    },
    mounted () {

    },
    methods: {

    }
}
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  margin-bottom:20px;
  background-color:rgba(248, 248, 248, 1);
  border-radius: 4px;
  min-height: 36px;
}
</style>
