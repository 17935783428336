<template>
  <div style="background-image: url(//edu-xr-bj.oss-cn-beijing.aliyuncs.com/website/new/object/hsdj/bgdj.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%; height:100vh;" id="hsdj">
    <div class="top-title">VR红色思政解决方案</div>
    <div class="title">　　通过VR技术创设沉浸式的体验环境，突破时空限制、走近革命先烈、学习榜样先锋，长期开展学生红色思政教育、爱国主义教育等，让学习者和更加生动和真切地亲历中华人民共和国的建国和发展历程，增强学习者的爱国主义意识，培养爱国主义情操。</div>
  </div>
</template>

<script>

export default {
  name: 'SliderBox',
  components: {

  },
  data () {
      return {
       
      }
  },
  created () {

  }
}
</script>
<style scoped>
.top-title{
    position: relative;
    top: 25%;
    width: 100%;
    color: #f8e800;
    font-weight: 700;
    font-style: normal;
    font-size: 46px;
    letter-spacing: 0.7px;
    text-align: center;
    text-shadow: 2px 2px 2px #686767;
}
.title{
    position: relative;
    top: 50%;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    font-size: 20px;
    line-height: 39px;
    color: #fff;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    text-shadow: 2px 2px 2px #686767;
}
</style>
