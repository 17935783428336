<template>
  <div class="home">
    <!--Main Slider-->
    <Slider id="huanyu"/>
<!--End Main Slider-->

<!-- /.features-style-one -->
    <FeaturesStyleOne/>

  </div>
  <el-affix :offset="60" v-show="isMob == false">
    <el-anchor :offset="100" id="anchor-left">
      <el-anchor-link :href="`#huanyu`">
        <h4>北京幻育</h4>
      </el-anchor-link>
      <el-anchor-link :href="`#zhengce`">
        <h4>政策背景</h4>
      </el-anchor-link>
      <el-anchor-link :href="`#yingyong`">
        <h4>八大应用方向</h4>
      </el-anchor-link>
      <el-anchor-link :href="`#selecthuanyu`">
        <h4>为什么选择幻育</h4>
      </el-anchor-link>
      <el-anchor-link :href="`#zizhi`">
        <h4>企业资质</h4>
      </el-anchor-link>
      <el-anchor-link :href="`#anli`">
        <h4>部分案例</h4>
      </el-anchor-link>
    </el-anchor>
  </el-affix>
</template>

<script>
import Slider from '../components/home/slider'
import FeaturesStyleOne from '../components/home/features-style-one'
import { addNonUsersLog } from '@/api/user_log'
import { isMobile } from '@/utils/tools'
export default {
  headers: {
    'Content-Type': 'application/json'
  },
  name: 'HomeView',
  isMob: false,
  components: {
    Slider,
    FeaturesStyleOne
  },
  created () {
    this.isMob = isMobile()
  },
  mounted () {
    this.addNonUsersLog()
  },
  methods: {
    addNonUsersLog(){
      var parms = {
        name:'访问官网',
        object:'访问官网首页'
      }
      addNonUsersLog(this.headers,parms)
    }
  }
}
</script>
<style scoped>
#anchor-left{
  z-index: 1000;
  position:fixed;
  left: 15px;
  width: 180px;
  bottom: 35%;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.21);
  padding: 12px;
  border-radius: 5px
}
/deep/.el-anchor__link{
  color: #f5f5f5;
}
/deep/.el-anchor__link:hover{
  color: #409eff;
}
</style>
